import '@ag-grid-community/all-modules/dist/styles/ag-grid.css'
import '@ag-grid-community/all-modules/dist/styles/ag-theme-balham.css'
import { GridApi } from '@ag-grid-community/core'
import { AllModules, ModuleRegistry } from '@ag-grid-enterprise/all-modules'
import { removeBlocklistMatchingsMutation, useLazyBlocklistMatchingsQuery } from '@curvo/apollo'
import { Button, message } from 'antd'
import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import { FetchDataParams, ServerSideGrid } from '../../../components/ServerSideGrid'
import { columns } from './BlocklistMatchingSuggestionColumns'

const BLOCKLIST_MATCHING_GRID = 'BLOCKLIST_MATCHING_GRID'

ModuleRegistry.registerModules(AllModules)
export const BlocklistMatchingSuggestion: React.FC = () => {
  const gridApiRef = useRef<GridApi | null>()

  const [getData, { loading }] = useLazyBlocklistMatchingsQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const [rowSelected, setRowSelected] = useState<boolean>(false)

  const handleRemoveRows = useCallback(() => {
    if (!gridApiRef.current) {
      return
    }
    const selectedRows = gridApiRef.current?.getSelectedRows()
    if (!selectedRows?.length) {
      return
    }
    gridApiRef.current?.showLoadingOverlay()
    return removeBlocklistMatchingsMutation({ ids: selectedRows.map(r => r.id) })
      .then(() => {
        message.success('Removed!')
        gridApiRef.current?.deselectAll()
        gridApiRef.current?.refreshServerSideStore({ purge: true })
      })
      .finally(() => gridApiRef.current?.hideOverlay())
  }, [])

  const fetchData = useCallback(
    (request: FetchDataParams) => {
      return getData({
        variables: {
          selectedFields: request.selectedFields,
          startRow: request.startRow,
          endRow: request.endRow,
          sorting: request.sorting,
          filter: request.filter,
        },
      }).then(resp => ({
        data: resp.data?.blocklistMatchings.edges.map(e => e.node) || [],
        total: resp.data?.blocklistMatchings.metadata.total || 0,
      }))
    },
    [getData],
  )

  return (
    <Container>
      <ToolbarContainer>
        <Button disabled={!rowSelected} icon="delete" onClick={handleRemoveRows}>
          Unblock
        </Button>
      </ToolbarContainer>
      <ServerSideGrid
        gridKey={BLOCKLIST_MATCHING_GRID}
        gridApiRef={api => {
          gridApiRef.current = api
        }}
        columns={columns}
        fetching={loading}
        fetchData={fetchData}
        gridProps={{
          rowSelection: 'multiple',
          onSelectionChanged: e => {
            setRowSelected(!!e.api.getSelectedRows().length)
          },
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  margin: 24px;
`

const ToolbarContainer = styled.div`
  margin: 16px 0;
  display: flex;
  gap: 8px;
`
